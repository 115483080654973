import React, { ReactEventHandler, useState } from "react";
import "./Login.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  loginAsync,
  registerAsync,
  selectUser,
} from "../../redux/slices/userSlice";
import { IUserLogin, IUserRegister } from "../../interfaces/user";
import { useAppDispatch } from "../../redux/hooks";
import { addItemToCart, clearCart } from "../../redux/api/cartApi";
import { selectCart } from "../../redux/slices/cartSlice";
import { useNavigate, useParams } from "react-router-dom";

type FormTypes = "login" | "register" | "forgot";

export default function Login() {
  const { formTypeParam } = useParams();
  const navigate = useNavigate();
  const [formType, setFormType] = useState<FormTypes>(
    (formTypeParam as FormTypes) || "login",
  );
  const [loginForm, setLoginForm] = useState<IUserLogin>({
    email: "",
    password: "",
  });
  const [error, setError] = useState<string | undefined>();

  const dispatch = useAppDispatch();
  const cart = useSelector(selectCart);

  const onSubmit = async (
    e: React.FormEvent<IUserRegister & HTMLFormElement>,
  ) => {
    e.preventDefault();
    const userRegister: IUserRegister = {
      email: "",
      newPassword: "",
      phoneNumber: "",
      confirmPassword: "",
      firstName: "",
      lastName: "",
    };
    Object.keys(userRegister).forEach((key: string) => {
      (userRegister as any)[key] = e.currentTarget[key].value;
    });
    const result = await dispatch(registerAsync(userRegister));
  };

  const onLoginSubmit = async () => {
    const result = await dispatch(loginAsync(loginForm));
    await clearCart();
    for await (const item of cart.items) {
      await addItemToCart({
        productId: item.product.id,
        quantity: item.quantity,
      });
    }
    navigate("/profile/dashboard");
  };

  return (
    <div id="logreg-forms">
      {formType === "login" ? (
        <div className="form-signin">
          <h1 className="h3 mb-3 font-weight-normal text-center">Giriş</h1>
          <input
            type="email"
            id="inputEmail"
            value={loginForm.email}
            onChange={(e) =>
              setLoginForm({ ...loginForm, email: e.target.value })
            }
            className="form-control"
            placeholder="Email"
            required
          />
          <input
            type="password"
            id="inputPassword"
            value={loginForm.password}
            onChange={(e) =>
              setLoginForm({ ...loginForm, password: e.target.value })
            }
            className="form-control"
            placeholder="Şifre"
            required
          />
          <button
            onClick={onLoginSubmit}
            className="btn btn-success btn-block"
            type="submit"
          >
            <i className="fas fa-sign-in-alt"></i> Giriş
          </button>
          <div
            className="backbtn"
            onClick={() => setFormType("forgot")}
            id="forgot_pswd"
          >
            Şifremi Unuttum
          </div>
          <hr />
          <button
            className="btn btn-primary btn-block"
            type="button"
            id="btn-signup"
            onClick={() => setFormType("register")}
          >
            <i className="fas fa-user-plus"></i>Yeni Hesap Oluştur
          </button>
        </div>
      ) : formType === "register" ? (
        <form className="form-signin form-register" onSubmit={onSubmit}>
          <h1 className="h3 mb-3 font-weight-normal text-center">Kaydol</h1>
          <input
            type="text"
            id="firstName"
            className="form-control"
            placeholder="Ad"
            required
          />
          <input
            type="text"
            id="lastName"
            className="form-control"
            placeholder="Soyad"
            required
          />
          <input
            type="email"
            id="email"
            className="form-control"
            placeholder="Email"
            required
          />
          <input
            type="phone"
            id="phoneNumber"
            className="form-control"
            placeholder="Telefon Numarası"
            required
          />
          <input
            type="password"
            id="newPassword"
            className="form-control"
            placeholder="Şifre"
            required
          />
          <input
            type="password"
            id="confirmPassword"
            className="form-control"
            placeholder="Şifre Tekrarı"
            required
          />

          <button className="btn btn-primary btn-block" type="submit">
            <i className="fas fa-user-plus"></i> Kaydol
          </button>
          <div
            className="backbtn"
            onClick={() => setFormType("login")}
            id="cancel_signup"
          >
            <i className="fas fa-angle-left"></i> Geri
          </div>
        </form>
      ) : (
        <form className="form-signin">
          <h1 className="h3 mb-3 font-weight-normal text-center">
            Şifremi Unuttum
          </h1>
          <input
            type="email"
            id="resetEmail"
            className="form-control"
            placeholder="Email"
            required
          />
          <button className="btn btn-primary btn-block" type="submit">
            Şifre Yenile
          </button>
          <div
            className="backbtn"
            onClick={() => setFormType("login")}
            id="cancel_reset"
          >
            <i className="fas fa-angle-left"></i> Geri
          </div>
        </form>
      )}
      {error && <div>{error}</div>}
    </div>
  );
}
