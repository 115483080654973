import "./Body.scss";
import Home from "./home/Home";
import { Route, Routes } from "react-router-dom";
import Login from "./login/Login";
import Profile from "./profile/Profile";
import Dashboard from "./profile/dashboard/Dashboard";
import Categories from "./categories/Categories";
import Garage from "./profile/garage/Garage";
import Cart from "./cart/Cart";
import Order from "./order/Order";
import Addresses from "./profile/address/Addresses";
import EditProfile from "./profile/editProfile/EditProfile";
import ChangePassword from "./profile/changePassword/ChangePassword";
import Orders from "./profile/orders/Orders";
import SearchProductChassisNo from "./seachProductChassisNo/SearchProductChassisNo";
import MaintainRobot from "./maintainRobot/MaintainRobot";
import VehicleProduct from "./vehicleProduct/VehicleProduct";
import Product from "./product/Product";

export default function Body() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/login/:formTypeParam?" element={<Login />} />
      <Route path="/categories/:categoryId" element={<Categories />} />
      <Route path="/product/:productId" element={<Product />} />
      <Route path="/search-products-chassis/:chassisNumber?" element={<SearchProductChassisNo />} />
      <Route path="/vehicle-product" element={<VehicleProduct />} />
      <Route path="/maintain-robot" element={<MaintainRobot />} />
      <Route path="/account" element={<Profile />}>
        <Route index element={<Dashboard />} />
        <Route path="garage" element={<Garage />} />
        <Route path="addresses" element={<Addresses />} />
        <Route path="edit-profile" element={<EditProfile />} />
        <Route path="change-password" element={<ChangePassword />} />
        <Route path="orders" element={<Orders />} />
      </Route>
      <Route path="/cart" element={<Cart />} />
      <Route path="/order" element={<Order />} />
      <Route path="*" element={<Home />} />
    </Routes>
  );
}
