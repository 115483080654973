import { AddCommentRequest } from "../../interfaces/comment";
import { fetchService, serverUrl } from "./fetchService";

export async function getProductComments(productId: string) {
  const path = `/Comments/getProductComments?productId=${productId}`;
  return await fetchService({ url: serverUrl + path, method: "GET" });
}

export async function addCommentToProduct(comment: AddCommentRequest) {
  const path = `/Comments/addComment?productId=${comment.productId}`;
  return await fetchService({ url: serverUrl + path, method: "POST", body: comment,catchAuthError: true,throwErrors: false });
}
