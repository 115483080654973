import { Link } from "react-router-dom";
import { toast } from "react-toastify";

interface IFetchService {
  url: string;
  method: "GET" | "POST" | "PUT" | "DELETE" | "PATCH";
  body?: Record<string, any> | null;
  headers?: Record<string, any>;
  catchAuthError?: boolean;
  throwErrors?: boolean;
}

export const serverUrl =
  process.env.NODE_ENV !== "production" ? "https://sonparca.com/api" : "/api";

export async function fetchService({
  url = "",
  method = "GET",
  body = null,
  headers = {},
  catchAuthError = true,
  throwErrors = true,
}: IFetchService) {
  // İsteğe özel başlıkları ayarlayın
  const defaultHeaders = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${localStorage.getItem("token")}`,
    // Gerekirse daha fazla varsayılan başlık ekleyin
  };

  try {
    const response = await fetch(url, {
      method,
      headers: { ...defaultHeaders, ...headers },
      body: body ? JSON.stringify(body) : null,
    });

    // Yanıtın başarılı olup olmadığını kontrol edin
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    let responseJson = {} as any;
    if (response.status !== 204) {
      try {
        responseJson = await response.json();
      } catch (error) {
        // ignore
      }
    }
    if (url.includes("signIn")) {
      if (responseJson.success) {
        const token = responseJson?.data?.token;
        localStorage.setItem("token", token!);
      }
    }
    // Yanıtı JSON formatına dönüştürün
    return responseJson;
  } catch (error: any) {
    // Hata yönetimi
    if (catchAuthError && error.message.includes("401")) {
      localStorage.clear();
      window.location.href = "/account";
    }
    handleFetchError(error);
    if (throwErrors) {
      throw new Error(`${error?.message}`);
    }
  }
}
function handleFetchError(error: any) {
  if ("HTTP error! Status: 401" === error.message) {
    toast(
      <div>
        Oturum açmanız gerekmetedir. Buradan
        <Link to="/login"> giriş yapabilirsiniz.</Link>
      </div>,
      { type: "error" },
    );
  } else if ("HTTP error! Status: 400" === error.message) {
    toast("Hatalı giriş bilgileri", { type: "error" });
  } else if ("HTTP error! Status: 404" === error.message) {
    toast("Aradığınız sonuç bulunmamaktadır. Lütfen tekrar deneyiniz.", { type: "error" });
  } else if ("HTTP error! Status: 500" === error.message) {
    toast("Sistem hatası oluşmaktadır. Lütfen sonra tekrar deneyiniz.", {
      type: "error",
    });
  } else if ("HTTP error! Status: 503" === error.message) {
    toast("Servis hatası oluşmaktadır. Lütfen sonra tekrar deneyiniz.", {
      type: "error",
    });
  } else if (error.message) {
    toast(error.message, { type: "error" });
  }
}
